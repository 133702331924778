<template>
    <li>
        <div>
            <a :href="userEmailLink">{{ email }}</a>
        </div>
        <p>{{ message }}</p>
        <button class="delete" @click="$emit('delete-request', id)">Delete</button>
    </li>
</template>

<script>
export default {
    emits: ['delete-request'],
    props: ['email', 'message', 'id'],
    computed:  {
        userEmailLink() {
            return "mailto:" + this.email;
        },
    },
}
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #ccc;
  padding: 1rem;
  position: relative;
}

a {
  color: #3d008d;
  text-decoration: none;
  font-weight: bold;
}

a:hover,
a:active {
  color: #8d007a;
}

p {
  margin: 0.5rem 0 0 0;
}

.delete {
  position: absolute;
  top:  1rem;
  right: 1rem;
  background-color: #8d0000;
  color: white;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: none;
}

.delete:focus {
  outline: none;
}
</style>