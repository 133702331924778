<template>
	<div class="main">
		<base-card>
			<header>
				<h2>Requests</h2>
			</header>
			<base-dialog :show="!!error" title="Request Error" @close="dismissError">
				{{ error }}
				<template #actions>
					<base-button @click="tryAgain">Try Again</base-button>
				</template>
			</base-dialog>
			<base-spinner v-if="isLoading"></base-spinner>
			<ul v-else-if="hasRequests">
				<request-item
					v-for="request in requests"
					:key="request.id"
					:email="request.userEmail"
					:message="request.message"
					:id="request.id"
					@delete-request="deleteRequest"
				></request-item>
			</ul>
			<h3 v-else>You haven't recieved any requests yet!</h3>
		</base-card>
	</div>
</template>

<script>
	import RequestItem from "../../components/requests/RequestItem.vue";

	export default {
		data() {
			return {
				isLoading: true,
				error: null,
			};
		},
		components: {
			RequestItem,
		},
		computed: {
			requests() {
				return this.$store.getters["requests/myRequests"];
			},
			hasRequests() {
				return (
					!this.isLoading &&
					this.$store.getters["requests/hasRequests"]
				);
			},
		},
		methods: {
			async deleteRequest(requestId) {
				try {
					await this.$store.dispatch("requests/delete", {
						requestId,
					});
				} catch (e) {
					this.error =
						e.error.message ??
						"Something went wrong while deleting the request from the server.";
				}
			},
			async loadRequests() {
				this.isLoading = true;
				this.error = null;

				try {
					await this.$store.dispatch("requests/loadRequests");
					this.isLoading = false;
				} catch (e) {
					this.error = e.error.message ?? "Something went wrong!";
				}
			},
			dismissError() {
				this.error = null
			},
			tryAgain() {
				this.error = null
				this.loadRequests();
			}
		},
		created() {
			this.loadRequests();
		},
	};
</script>

<style scoped>
	header {
		text-align: center;
	}

	ul {
		list-style: none;
		margin: 2rem auto;
		padding: 0;
		max-width: 30rem;
	}

	h3 {
		text-align: center;
	}
</style>
